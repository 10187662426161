<template>
    <div class="row">
        <div style="text-align: center;"><small><small><small>
                        <p>System is licensed for authorized customers.<br>Unauthorized use subject to legal
                            recourse.<br>© 2025, ® &amp; Powered by <a class="footer"
                                href="http://qrcodeexperts.com">JT.ADMAN. QRCodeExperts.net </a></p>
                    </small></small></small></div>
    </div>
</template>

<script>

</script>

<style></style>