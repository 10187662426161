<template>
    <div class="home">
        <h1>{{ this.description }}</h1>
        <div class="heading">
            <h2>UPC-QR CODE<br />
                Masters</h2>
            <h3>Our system makes a re-directable QR code with
                your UPC code!</h3>
            <h4 class="italicH4">Handle marketing and SKU
                management in one space!</h4>
            <p>Our company will train your staff to operate the system easily and quickly.</p>
            <!--<div>-->
            <img class="barcode" alt="Mixed Code" src="../assets/UPCandQR.png" />
            <!--</div>-->
        </div>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'HomeView',
    display: "Home",
    data() {
        return {
            description: "QRCodeExperts.Net"
        }
    },
}

</script>

<style>
h4.italicH4 {
    font-style: italic;
}

a.footer {
    font-weight: bold;
    color: white;
    text-decoration: none;
    padding: 10px;
}

div.heading {
    color: white;
    margin: 0 auto;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background: rgb(46, 108, 183);
    width: 300px;
}

img.barcode {
    width: 100%;
    object-fit: contain;
}

img.footerLogo {
    vertical-align: middle;
}
</style>
