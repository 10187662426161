<template>
    <div class="row" style="padding-top:5px;">
        <div class="col-1">
            <router-link to="/"><img alt="QRCodeExperts.net" src="../assets/logo-qrcodeexperts.png"
                    class="logo" /></router-link>
        </div>
        <div class="col-11">
            <div class="row">
                <div class="col-12">
                    <div class="col-1 float-end" :key="componentKey" v-if="theAuthCookie && theAuthCookie.owneruserid">
                        <span><small>Welcome {{ ownername }} ({{
                            owneruserid }})</small></span>
                    </div>
                    <div class="col-1 float-end" v-else><span><small><!--No Login /--></small></span></div>
                    <div class="col-11">
                        <Navbar :key="$route.fullPath" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from "./Navbar.vue"
import { useCookies } from "vue3-cookies"
const { cookies } = useCookies()
import { getUser } from "../libs/libs.js"

async function setupAuthInfo(authInfo) {
    if (authInfo && authInfo.owneruserid) {
        this.user = await getUser(authInfo.owneruserid)
        this.owneruserid = this.user.id
        this.ownerusername = this.user.username
        this.ownername = `${this.user.fname} ${this.user.lname}`
    }
}

async function buildAuth() {
    return cookies.get('theAuthCookie')

}

export default {
    components: { Navbar },
    async data() {
        return {
            outputFormat: this.$route.query.of,
            user: null,
            owneruserid: null,
            ownerusername: null,
            ownername: null,
            theAuthCookie: await buildAuth(),
            componentKey: 0,
        }
    },
    async mounted() {
        //this.theAuthCookie = buildAuth()
        setupAuthInfo(this.theAuthCookie)
        if (this.outputFormat == 1) {
            console.log("toprow outputFormat 1")
        }
    },
    methods: {
        forceRerender() {
            //this.theAuthCookie = buildAuth();
            setupAuthInfo(this.theAuthCookie)
            this.componentKey += 1;
        }
    }

}
</script>

<style>
.logo {
    width: 80px;
}
</style>