<template>
    <div v-if="code && user">
        <div> <!-- non-wizi -->
            <div class="col-12">
                <h1>{{ this.description }}</h1>
            </div>
            <div class="row">&nbsp;</div>
            <div class="row">
                <div class="col-3"><b><u>Key</u></b></div>
                <div class="col-9"><b><u>Value</u></b></div>
            </div>
            <div class="row">
                <div class="col-3 border rounded border-secondary align-middle"><b>Owner</b></div>
                <div class="col-9 border rounded border-secondary">{{ owneruserid }}<br />{{ ownername
                    }}<br />{{ ownerusername }}</div>
            </div>
            <div class="row">
                <div class="col-3 border rounded border-secondary align-middle"><b>Style</b></div>
                <div class="col-9 border rounded border-secondary">{{ codeStyle }} ({{ code.selectedStyleSelectOption
                    }})</div>
            </div>
            <div class="row">
                <div class="col-3 border rounded border-secondary align-middle"><b>Code ID's</b></div>
                <div class="col-9 border rounded border-secondary"><a :href="codeadurl" target="_blank">Advertizement {{ code.codeid
                        }}</a><br />{{ code.shortcodeid }}</div>
            </div>
            <div class="row">
                <div class="col-3 border rounded border-secondary align-middle"><b>Business Name:</b></div>
                <div class="col-9 border rounded border-secondary">{{ code.BusName }}</div>
            </div>
            <div v-if="code.selectedStyleSelectOption == 1" class="row">
                <div class="col-3 border rounded border-secondary align-middle"><b>Redirect URL</b></div>
                <div class="col-9 border rounded border-secondary">{{ code.redirecturl }}</div>
            </div>
            <div v-if="code.selectedStyleSelectOption == 2 && code.desturl" class="row">
                <div class="col-3 border rounded border-secondary align-middle"><b>Web URL</b></div>
                <div class="col-9 border rounded border-secondary">{{ code.desturl }}</div>
            </div>
            <div v-if="code.selectedStyleSelectOption == 2 && code.phone" class="row">
                <div class="col-3 border rounded border-secondary align-middle"><b>Phone Number</b></div>
                <div class="col-9 border rounded border-secondary">{{ code.phone }}</div>
            </div>
            <div v-if="code.selectedStyleSelectOption == 2 && code.location" class="row">
                <div class="col-3 border rounded border-secondary align-middle"><b>Location</b></div>
                <div class="col-9 border rounded border-secondary">{{ code.location }}</div>
            </div>
            <div class="row">
                <div class="col-3 border rounded border-secondary align-middle"><b>Last Modified</b></div>
                <div class="col-9 border rounded border-secondary">{{ Intl.DateTimeFormat('en-US',dtOptions).format(new
                    Date(code.lastmodify)) }}</div>
            </div>
            <div class="row">
                <div class="col-3 border rounded border-secondary align-middle"><b>QRCode</b></div>
                <div class="col-9 border rounded border-secondary"><img :src="qrcodeurl" /></div>
            </div>
            <router-link :to="'/editcode/' + this.codeid"><button class="btn btn-primary">Edit
                    Code</button></router-link>

        </div>
    </div>
    <div v-else>
        <p>Loading code details... {{ codeidQuery }} - {{ codeid }}</p>
    </div>
</template>

<script>
import { getUser } from "../../libs/libs.js"

const dpiSelectOptions = [
    {
        name: "Web (96dpi)",
        value: 96
    },
    {
        name: "Print (384dpi)",
        value: 384
    },
]
const styleSelectOptions = [
    {
        name: "Redirect Only",
        value: 1
    },
    {
        name: "Traditional",
        value: 2
    },
]


export default {
    name: 'CodeDetails',
    display: "Codes Details",
    props: ["codeid"],
    data() {
        return { 
            description: "View Code",
            imgsrc: "",
            code: null,
            user: null,
            codeidQuery: this.codeid,
            codeadurl: null,
            qrcodeurl: null,
            outputFormat: this.$route.query.of,
            codeStyle: 'None',
            owneruserid: '',
            ownerusername: '',
            ownername: '',
            displayUserStuffDetail: false,
            dtOptions: {
                year: 'numeric', 
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric', timeZoneName: 'short'
            }
        }
    },
    methods: {
        handleDisplayUserStuffDetail() {
            this.displayUserStuffDetail = !this.displayUserStuffDetail
        },
        jsonPretty(val, indent = 2) {
            if (typeof val === "string") {
                val = JSON.parse(val)
            }
            return JSON.stringify(val, null, indent)
        },

    },
    async mounted() {
        if (this.outputFormat === 1) {
            console.log("outputFormat 1")
        } else {
            console.log("no outputFormat")
        }

        this.codeadurl = `${process.env.VUE_APP_ROOT_URL}codead/${this.codeidQuery}`
        let url = `${process.env.VUE_APP_CODEROOT_URL}api/codes?codeid=${this.codeidQuery}`
        console.log(`outputFormat: ${this.outputFormat}, url: ${url}, `)
        fetch(url)
            .then(res => res.json())
            .then(data => {
                this.code = data[0]
            })
            .then(() => {
                console.log(`code loaded ${JSON.stringify(this.code)}`)
                this.qrcodeurl = `${this.code.qrcodeinfo.codedata.qrCodeUrl}`
                this.codeStyle = this.code && this.code.selectedStyleSelectOption ? styleSelectOptions.find(styleSelectOption => {
                    return styleSelectOption.value ==
                        this.code.selectedStyleSelectOption
                }).name : 'None';
            })
            .then(async () => {
                console.log(`code owneruserid ${this.code.owneruserid}`)
                this.user = await getUser(this.code.owneruserid)
                this.owneruserid = this.user.id
                this.ownerusername = this.user.username
                this.ownername = `${this.user.fname} ${this.user.lname}`
            })
            .catch(err => {
                console.log(err.message)
            })
        console.log(`code data loaded...`);

    },
    components: {

    }

}
</script>

<style>
body {
    margin: 0;
    font-size: 13px;
    line-height: 1.231;
}

body,
button,
input,
select,
textarea {
    font-family: sans-serif;
    color: #222;
}

a:hover,
a:active {
    outline: 0;
}

img {
    border: 0;
    -ms-interpolation-mode: bicubic;
    vertical-align: middle;
}

wizi {
    background-color: #f5f5f5;
}

div.wiziImage {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}

Footer {
    background-color: #f5f5f5;
}

.whiteboxshadow {
    color: black;
    background-color: #f5f5f5;
    box-shadow: 4px 4px 4px #919191;
}

.buttonrow {
    height: 80px;
}

.adtoprow {
    height: 5px;
}

.biztext {
    font-size: 1em;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}

.bizinfocontainer {
    height: 100%;
    width: 100%;
}

.bizoffercontainer {
    height: 40%;
    width: 100%;
}

.btn {
    font-weight: bold;
    /*color:red;*/
    font-size: 2em;
}

/* DivTable.com */
.divTable {
    display: flex;
    text-align: left;
    display: table;
    width: 50%;
}

.divTableRow {
    display: table-row;
}

.divTableHeading {
    background-color: #EEE;
    display: table-header-group;
}

.divTableCell,
.divTableHead {
    /*border: 1px solid #999999;*/
    display: table-cell;
    padding: 3px 10px;
    width: 50%;
}

.divTableHeading {
    background-color: #EEE;
    display: table-header-group;
    font-weight: bold;
}

.divTableFoot {
    background-color: #EEE;
    display: table-footer-group;
    font-weight: bold;
}

.divTableBody {
    display: table-row-group;
}
</style>