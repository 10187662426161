<template>
    <TopRow v-if="$route.meta.showNavigation" :key="componentKey" ref="forceRerender" />
    <div class="container" style="padding-bottom: 20px;">
        <div class="row" style="margin-bottom: 15px">
            <router-view />
        </div>
    </div>
    <Footer />
</template>

<script>
import { useCookies } from "vue3-cookies"
const { cookies } = useCookies()
import TopRow from "./components/TopRow.vue"
import Footer from "./components/Footer.vue"

console.log(`VUE_APP_ROOT_URL: ${process.env.VUE_APP_ROOT_URL}`);
console.log(`VUE_APP_CODEROOT_URL: ${process.env.VUE_APP_CODEROOT_URL}`);
console.log(`VUE_APP_QRCODEROOT_URL: ${process.env.VUE_APP_QRCODEROOT_URL}`);
console.log(`BASE_URL: ${process.env.BASE_URL}`);
async function buildAuth() {
    return cookies.get('theAuthCookie')
}

import { useRoute } from 'vue-router'
import { computed } from 'vue'



export default {
    components: { TopRow, Footer },
/*
    setup() {
        const route = useRoute()

        const path = computed(() => route.path)
        const routeName = route.name
        const showNavigation = route.meta.showNavigation
        const outputFormat = route.query.of

        return {
            path: path,
            routeName:routeName,
            showNavigation: showNavigation,
            outputFormat: outputFormat
        }
    },
*/
    data() {
        return {
            outputFormat: this.outputFormat,
            componentKey: 0,
            theAuthCookie: buildAuth(),
        }
    },
    async mounted() {
        //setupAuthInfo(this.theAuthCookie)
        if (this.outputFormat == 1) {
            console.log("app outputFormat 1")
        }
        console.log(`routerName: ${this.routerName}, showNavigation: ${this.showNavigation}, outputFormat: ${this.outputFormat}, componentKey: ${this.componentKey}, theAuthCookie: ${this.theAuthCookie}`)
    },
    methods: {
        forceRerender() {
            //setupAuthInfo(this.theAuthCookie)
            this.componentKey += 1;
        }
    }
}
</script>

<style>
body {
    background: #b0b9bb;
    margin: 8px;
}

#app {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background: #b0b9bb;
}
</style>
