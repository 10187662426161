<template>
    <div v-if="code">
        <!--h1>QRCodeExperts Advertisement</!--h1-->
        <div class="container-fluid p-0">
            <div class="row full-screen-row m-0">
                <div class="col text-center d-flex flex-column justify-content-center align-items-center">
                    <div v-if="code.offerImage" class="wiziImage">
                        <img :src="code.offerImage" />
                    </div>
                    <div v-else class="wiziText biztext">
                        <h1>{{ code.BusName }}</h1>
                    </div>
                </div>
            </div>
            <div class="row m-0">
                <div v-if="code.location" class="col p-0">
                    <a :href="locationurl" target="_blank" rel="noopener noreferrer">
                        <button type="button" class="btn btn-custom w-100" id="admap">MAP</button>
                    </a>
                </div>
                <div v-if="code.phone" class="col p-0">
                    <a :href="'tel:' + code.phone" target="_blank" rel="noopener noreferrer">
                        <button type="button" class="btn btn-custom w-100" id="adcall">CALL</button>
                    </a>
                </div>
                <div v-if="code.desturl" class="col p-0">
                    <a :href="code.desturl" target="_blank" rel="noopener noreferrer">
                        <button type="button" class="btn btn-custom w-100" id="adweb">WEB</button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'CodeAd',
    display: "Codes Advertisement",
    props: ["codeid"],
    functional: false,
    data() {
        return {
            description: "Code Advertisement",
            outputFormat: false,
            code: null,
            codeidQuery: null,
            locationurl: null,
        }
    },
    created: async function() {
        this.codeidQuery = this.codeid

        let url = `${process.env.VUE_APP_CODEROOT_URL}api/codes?codeid=${this.codeidQuery}`
        console.log(`outputFormat: ${this.outputFormat}, url: ${url}, `)
        fetch(url)
            .then(res => res.json())
            .then(data => {
                this.code = data[0]
            })
            .then(() => {
                console.log(`code loaded ${JSON.stringify(this.code)}`)
                if (this.code.redirecturl) {
                    window.location.replace(this.code.redirecturl)
                } else {
                    //fix location value url
                    this.locationurl = null;
                    if (this.code.location) {
                        this.locationurl = `https://maps.google.com/?q=${encodeURI(this.code.location)}`
                    }
//                    this.$forceUpdate();
                }
            })
            .catch(err => {
                console.log(err.message)
            })
        console.log(`code data loaded...`);
    },
    mounted: async function () {
        this.description = "Code Advertisement"
        this.outputFormat = this.$route.query.of

        if (this.outputFormat === 1) {
            console.log("outputFormat 1")
        } else {
            console.log("no outputFormat")
        }
    },
}

</script>

<style scoped>
body {
    margin: 0;
    font-size: 13px;
    line-height: 1.231;
}

body,
button,
input,
select,
textarea {
    font-family: sans-serif;
    color: #222;
}

a:hover,
a:active {
    outline: 0;
}

img {
    border: 0;
    -ms-interpolation-mode: bicubic;
    vertical-align: middle;
}

.btn-custom {
    background-color: #fff;
    /* White background */
    color: #000;
    /* Black text */
    border: 1px solid #ccc;
    /* Optional border */
}

/* Ensure visited state does not change text color (affects <a> elements) */
.btn-custom:visited {
    color: #000;
}

.full-screen-row {
    border: 1px solid #000;
    /* Single line border */
    height: 60vh;
    /* Full viewport height */
}

wizi {
    background-color: #f5f5f5;
}

div.wiziImage {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}

Footer {
    background-color: #f5f5f5;
}

.whiteboxshadow {
    color: black;
    background-color: #f5f5f5;
    box-shadow: 4px 4px 4px #919191;
}

.buttonrow {
    height: 80px;
}

.adtoprow {
    height: 5px;
}

.biztext {
    font-size: 1em;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}

.bizinfocontainer {
    height: 100%;
    width: 100%;
}

.bizoffercontainer {
    height: 40%;
    width: 100%;
}

.btn {
    font-weight: bold;
    /*color:red;*/
    font-size: 2em;
}

/* DivTable.com */
.divTable {
    display: flex;
    text-align: left;
    display: table;
    width: 50%;
}

.divTableRow {
    display: table-row;
}

.divTableHeading {
    background-color: #EEE;
    display: table-header-group;
}

.divTableCell,
.divTableHead {
    /*border: 1px solid #999999;*/
    display: table-cell;
    padding: 3px 10px;
    width: 50%;
}

.divTableHeading {
    background-color: #EEE;
    display: table-header-group;
    font-weight: bold;
}

.divTableFoot {
    background-color: #EEE;
    display: table-footer-group;
    font-weight: bold;
}

.divTableBody {
    display: table-row-group;
}
</style>